// export const GA_TRACKING_ID = "G-HKRDJ7G169";
// // Initialize Google Analytics
// export const initGA = () => {
//     window.dataLayer = window.dataLayer || [];
//     function gtag() {
//         window.dataLayer.push(arguments);
//     }
//     gtag("js", new Date());
//     gtag("config", GA_TRACKING_ID);
// };
// // Log page views
// export const logPageView = (url) => {
//     window.gtag("config", GA_TRACKING_ID, {
//         page_path: url,
//     });
// };

export const GA_TRACKING_ID = "G-HKRDJ7G169";

// Initialize Google Analytics
export const initGA = () => {
  if (!window.gtag) {

    console.log('loading gtag script');
    // Load the gtag script
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;

      gtag("js", new Date());
      gtag("config", GA_TRACKING_ID);
    };
  } else {
    console.log('gtag already loaded');
    window.gtag("js", new Date());
    window.gtag("config", GA_TRACKING_ID);
  }
};

// Log page views
export const logPageView = (url, title) => {
  if (window.gtag) {
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
      page_title: title,
    });
  } else {
    console.error("gtag is not defined");
  }
};
