import React from "react";
import logolight from "../assets/images/logo-light.png";
import { Col, Container, Row, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

const ListCard = (props) => {
  return (
    <React.Fragment>
      <div className={props.isList ? "col-lg-3 col-md-6" : "col-lg-2 col-md-6"}>
        <div className={props.isShow ? "mt-4 ps-0 ps-lg-5" : "mt-4"}>
          <h6 className="text-white text-uppercase fs-16">{props.title}</h6>
          <ul className="list-unstyled footer-link mt-3 mb-0">
            {props.isChildList.map((item, key) => (
              <li key={key}>
                {item?.link?.[0] === '/' 
                    ? <NavLink href={item.link} alt="work">
                        {item.subList}
                    </NavLink>
                    : <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.subList}
                  </a>
                }
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

const ListData = [
  {
    title: "Resources",
    isList: true,
    isShow: true,
    isChildList: [
    //   { id: 1, subList: "Company History" },
    //   { id: 2, subList: "Documentation" },
      { id: 1, subList: "Terms & Conditions", link: '/terms' },
    //   { id: 4, subList: "Components" },
    ],
  },
  {
    title: "Help",
    isList: false,
    isShow: false,
    isChildList: [
      { id: 1, subList: "Sign Up", link: '/#contact' },
      { id: 2, subList: "Login", link: 'https://app.citras.io' },
      { id: 3, subList: "Careers", link: 'https://forms.citras.io/#contact' },
      { id: 4, subList: "Privacy Policy", link: '/privacy-policy' },
    ],
  },
];

// {
//   iconClass: "mdi mdi-facebook",
// },
// {
//     iconClass: "mdi mdi-pinterest",
// },
const ListIconData = [
  {
    iconClass: "mdi mdi-linkedin",
    url: 'https://www.linkedin.com/company/citrasolutions'
},
{
    iconClass: "mdi mdi-twitter",
    url: 'https://twitter.com/CitraSolutions'
  },
];

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="bg-footer">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="mt-4">
                <Link to="/">
                  <img src={logolight} alt="" height="44" />
                </Link>
                <p className="text-white-50 mt-3 pt-2 mb-0 ">
                Experts in Modern Data Platforms for SAP Customers
                </p>
                <div className="mt-4">
                  <ul className="list-inline footer-social">
                    {/* Footer Icon Component Start */}
                    {ListIconData.map((item, key) => (
                      <li className="list-inline-item" key={key}>
                        <Link to={item.url}>
                          <i className={item.iconClass}></i>
                        </Link>
                      </li>
                    ))}
                    {/* Footer Icon Component End */}
                  </ul>
                </div>
              </div>
            </Col>

            {/* Footer List Start*/}

            {ListData.map((item, key) => (
              <ListCard
                key={key}
                isList={item.isList}
                isShow={item.isShow}
                title={item.title}
                isChildList={item.isChildList}
              />
            ))}
            {/* Footer List End*/}

            <Col lg={4}>
              <div className="mt-4">
                <h6 className="text-white text-uppercase fs-16">Latest News</h6>
                <div className="mt-3 mb-0">
                  <div className="d-flex">
                    <i className="mdi mdi-twitter text-white-50 float-start"></i>
                    <div className="flex-grow-1">
                      <p className="text-white-50 ps-3">
                        
                      </p>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex">
                      <i className="mdi mdi-twitter text-white-50 float-start"></i>
                      <div className="flex-grow-1">
                        <p className="text-white-50 ps-3">
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
