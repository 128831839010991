import React, {useState, useEffect} from "react";
import axios from 'axios';
import NavBar from "../../Layout/Navbar";
import HeaderSection from '../../components/HeaderSection';
import { useParams } from 'react-router-dom';
import { blogApiURL } from "../../globalVariables";
import { initGA, logPageView } from "../../utils/gtag";
import parse from 'html-react-parser';

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

const BlogPost = () => {

    // state and params
    const { blogId } = useParams(); // Extract the blogId parameter from the URL
    const [loading, setLoading] = useState();
    const [blogData, setBlogData] = useState();
    const [blogMetadata, setBlogMetadata] = useState([]);


    // console.log('blogId: ', blogId);
    // console.log('blogData: ', blogData);
    // console.log('blogMetadata: ', blogMetadata);

    useEffect(() => {
        
        // set document title
        if (blogMetadata) {
            const pageTitle = blogMetadata?.shortName ?? blogMetadata?.title;
            document.title = pageTitle;
            initGA();
            // logPageView(window.location.pathname);
            logPageView(window.location.pathname, pageTitle);
        }

        // console.log('logging page view: ', window.location.pathname);
    }, [blogMetadata]);
    
    
    useEffect(() => {
        
      
        const fetchBlogData = async () => {

            setLoading(true);
            
            // const wait = (seconds) => new Promise(resolve => setTimeout(resolve, seconds * 1000));
            // await wait(1);

            try {
                
                // get blog list for readtimes and other metadata
                const res = await axios.get(`${blogApiURL}/list`, {
                    params: {'type': 'blogs'}
                }
                );
                // console.log('res: ', res);
                const filteredRes = res.data?.filter(obj => obj.id === blogId)?.[0];
                setBlogMetadata(filteredRes);                
                
                // get blog post data
                const response = await axios.get(`${blogApiURL}/post?blogId=${blogId}`);
                setBlogData(response.data);

            } catch (error) {
                console.error('Error fetching blog data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogData();

      }, [blogId]);

    let content = <HeaderSection 
        title=''
        subtitle=''
        mode='loading'
    />

    if (!loading && blogData && blogMetadata) {

        // blog rendered from json array if not pdf
        let dynamicBlogContent = <p className="text-muted mt-3"><i>Blog will open in a new tab. If the tab does not open automatically, please click 
            <a href={blogData.pdf} target="_blank" rel="noopener noreferrer">&nbsp;here.</a></i></p>;
        if (blogData?.content) {
            dynamicBlogContent = blogData.content.map((section, index) => {
                // if (section.type === 'paragraph') {
                //     if (section?.formatting?.includes('i')) {
                //         return <p key={index}><i>{section.text}</i></p>;
                //     } else {
                //         return <p key={index}>{section.text}</p>;
                //     }
                // }
                if (section.type === 'paragraph') {
                    const content = parse(section.text); // Parse the text to render HTML tags
          
                    if (section?.formatting?.includes('i')) {
                      return <p key={index}><i>{content}</i></p>;
                    } else {
                      return <p key={index}>{content}</p>;
                    }
                  }                
                if (section.type === 'header') {
                    if (section.subtype === 'h1') {
                        return <h1 className="mt-4" key={index}>{section.text}</h1>
                    } else if (section.subtype === 'h2') {
                        return <h2 className="mt-4" key={index}>{section.text}</h2>
                    } else if (section.subtype === 'h3') {
                        return <h3 className="mt-4" key={index}>{section.text}</h3>
                    } else if (section.subtype === 'h4') {
                        return <h4 className="mt-4" key={index}>{section.text}</h4>
                    } else if (section.subtype === 'h5') {
                        return <h5 className="mt-4" key={index}>{section.text}</h5>
                    } else if (section.subtype === 'h6') {
                        return <h6 className="mt-4" key={index}>{section.text}</h6>
                    } else {
                        return <h4 className="mt-4" key={index}>{section.text}</h4>
                    }
                }
                if (section.type === 'list') {
                    return (
                    <ul key={index}>
                        {section.items.map((item, idx) => (
                        <li key={idx}>{parse(item)}</li>
                        ))}
                    </ul>
                    );
                }
                if (section.image) {
                    return <img className="img-fluid mb-3" key={index} src={section.image} alt={`Visual aid for blog ${index + 1}`} />;
                }
                return null;
                
            });

        } else if (blogData?.pdf) {
            openInNewTab(blogData.pdf);
        }

        content = (
            <>
                <HeaderSection 
                    title={blogMetadata?.title}
                    subtitle={`${blogMetadata?.author} - ${blogMetadata?.readTime} - ${new Date(blogMetadata.datePublished).toLocaleDateString()}`}
                    mode='subcomponent'
                />
                <section className="blog-post">
                    {dynamicBlogContent}
                </section>

            </>
        );
    }


    return (
        <React.Fragment>
        <NavBar isDark={false} />
        {/* import Section */}
        {content}
        </React.Fragment>
    );
};

export default BlogPost;
