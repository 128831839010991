import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import axios from 'axios';
import {blogApiURL} from '../globalVariables';
// import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

//Import Images
// import img1 from "../assets/images/portfolio/img-1.jpg";
// import img2 from "../assets/images/portfolio/img-2.jpg";

// const GalleryData = [
//   {
//     image: img1,
//     title: "Motion Graphic",
//     categories: ["Photography", "Development"],
//   },
//   {
//     image: img2,
//     title: "Creative Flyer",
//     categories: ["Photography"],
//   },
//   {
//     image: img3,
//     title: "Stories Collective",
//     categories: ["Development"],
//   },
//   {
//     image: img4,
//     title: "Lightning Shot",
//     categories: ["Project", "Design"],
//   },
//   {
//     image: img5,
//     title: "Mockup Collection",
//     categories: ["Development", "Project", "Design"],
//   },
//   {
//     image: img6,
//     title: "Company V-card",
//     categories: ["Photography"],
//   },
//   {
//     image: img7,
//     title: "Mockup box with paints",
//     categories: ["Development", "Design"],
//   },
//   {
//     image: img8,
//     title: "Pen and article",
//     categories: ["Photography"],
//   },
// ];

const Gallery = () => {
  const [displayCategory] = useState("All");
//   const [photoIndex, setphotoIndex] = useState(0);
//   const [isOpen, setisOpen] = useState(false);

  const [products, setBlogs] = useState([]);

//   console.log('products: ', products);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
          const response = await axios.get(`${blogApiURL}/list`, {
              params: {'type': 'products'}
          }
        );
        
        // // temp hardcoded testing
        // const categories = ['Data Analysis and Transformation', 'Cloud-Based Form Solutions'];
        // const imgs = [img1, img2];
        // response.data.forEach((obj, i) => {
        //     obj.categories = [categories[i]]
        //     obj.image = imgs[i]
        // });

        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);  

  return (
    <React.Fragment>
      <section className="section bg-light" id="products">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">Products</p>
                <h3>Our Solutions</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                We develop innovative software solutions and data-driven strategies to create impactful experiences that deliver value and drive success.
                </p>
              </div>
            </Col>
          </Row>
          {/* <Row className="my-4 pt-2">
            <Col lg={12}>
              <div className="filters-group">
                <ul className="nav filter-options list-unstyled list-inline justify-content-center">
                  <li
                    className={
                      displayCategory === "All"
                        ? "nav-link list-inline-item mt-2 active"
                        : "nav-link list-inline-item mt-2"
                    }
                    onClick={() => setCategory("All")}
                  >
                    All
                  </li>

                  <li
                    className={
                      displayCategory === "Data Analysis and Transformation"
                        ? "nav-link list-inline-item mt-2 active"
                        : "nav-link list-inline-item mt-2"
                    }
                    onClick={() => setCategory("Data Analysis and Transformation")}
                  >
                    Data Analysis and Transformation
                  </li>

                  <li
                    className={
                      displayCategory === "Cloud-Based Form Solutions"
                        ? "nav-link list-inline-item mt-2 active"
                        : "nav-link list-inline-item mt-2"
                    }
                    onClick={() => setCategory("Cloud-Based Form Solutions")}
                  >
                    Cloud-Based Form Solutions
                  </li>


                </ul>
              </div>
            </Col>
          </Row> */}

          
        </Container>

        <Container className="mt-5" fluid={true}>
            <Row className="g-3 justify-content-center" id="grid">
              {products?.filter(
                ({ categories }) =>
                  categories.includes(displayCategory) ||
                  displayCategory === "All"
              ).map(({ title, thumbnail_url, categories, link }, key) => (
                <Col
                  key={key}
                  className="picture-item"
                  data-groups={categories}
                  md={3}
                  lg={3}
                >
                  <div className="portfolio-box rounded">
                    <Link to={link} target="_blank" rel="noopener noreferrer" className="text-primary">
                        <img className="img-fluid" src={thumbnail_url} alt="work-img" />
                        <div className="portfolio-content">
                        <div className="img-view">
                            {/* <i
                            onClick={() => {
                                console.log(key);
                            }}
                            className="mdi mdi-plus text-muted image-popup"
                            ></i> */}
                            <div className="gallery-overlay"></div>
                        </div>
                        <div className="portfolio-caption">
                            {/* <Link to={link} target="_blank" rel="noopener noreferrer" className="text-primary"> */}
                            <h5 className="mb-1 fs-18">{title}</h5>
                            {/* </Link> */}
                            <p className="mb-0">{categories.join(", ")}</p>
                        </div>
                        </div>
                    </Link>
                  </div>
                  {/* <!--end portfolio-box--> */}
                </Col>
              ))}

              {/* lightbox portfolio images */}

              {/* {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setisOpen(false)}
                  onMovePrevRequest={() =>
                    setphotoIndex(
                      (photoIndex + images.length - 1) % images.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setphotoIndex((photoIndex + 1) % images.length)
                  }
                />
              )} */}
            </Row>
          </Container>
      </section>
    </React.Fragment>
  );
};
export default Gallery;
