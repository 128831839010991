import React from "react";
import NavBar from "../../Layout/Navbar";
// import Section from "./Section";
import HeaderSection from "../../components/HeaderSection";

const Layout1 = () => {
  return (
    <React.Fragment>
      <NavBar isDark={false} />
      {/* import Section */}
      {/* <Section /> */}
      <HeaderSection 
            title='Experts in Modern Data Platforms for SAP Customers'
            subtitle='Unlocking the full potential of your data to deliver actionable insights and drive business success'
            mode='home'
        />      
    </React.Fragment>
  );
};

export default Layout1;
