import React,  {useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import {blogApiURL} from '../globalVariables';


// import img1 from "../assets/images/users/img-1.jpg";
// import img2 from "../assets/images/users/img-2.jpg";
// import img3 from "../assets/images/users/img-3.jpg";
// import img4 from "../assets/images/users/img-4.jpg";
// import img5 from "../assets/images/users/img-5.jpg";
// import img6 from "../assets/images/users/img-6.jpg";
// import img7 from "../assets/images/users/img-7.jpg";

const TeamCard = (props) => {


  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className="team-box text-center">
          <div className="team-img position-relative">
            <img src={props.imgsrc} className="img-fluid rounded" alt="" />
            <div className="team-content">
              <ul className="list-inline mb-0 p-0">
                {props.icons.map((item,key) => (
                <li key={key} className="list-inline-item">
                <Link to={item.link} target="_blank" rel="noopener noreferrer" >
                  <i className={item.icon}></i>
                </Link>
              </li>  
                ))}
              </ul>
            </div>
          </div>
          <div className="p-3">
            <Link to="/#" className="text-primary">
              <h5 className="fs-18 mb-1">{props.name}</h5>
            </Link>
            <p className="mb-0">{props.title}</p>
          </div>
        </div>
        {/* <!--end team-box--> */}
      </Col>
    </React.Fragment>
  );
}



const Team = () => {

    const [people, setPeople] = useState([]);

    // console.log('people: ', people);

    useEffect(() => {

        const fetchPeople = async () => {

          try {
              const response = await axios.get(`${blogApiURL}/list`, {
                  params: {'type': 'people'}
              }
            );
            // // temp hardcoded testing
            // response.data.forEach((obj, i) => {
            //     obj.icons = [
            //         {icon : "mdi mdi-linkedin"},
            //       ]
            // });

            setPeople(response.data);
          } catch (error) {
            console.error('Error fetching people:', error);
          }
        };
    
        fetchPeople();
      }, []);
          


  return (
    <React.Fragment>
      <section className="section" id="team">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">
                  Meet our Experts
                </p>
                <h3>Your Partners in Innovation</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                Discover some of the talented professionals driving innovation and excellence in our field.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 justify-content-center">
            {/* Team Detail Component Start */}

            {people && people.filter(obj => obj.title === 'Partner').map((value, idx) => (
              <TeamCard
                key={idx}
                imgsrc={value.thumbnail_url}
                name={value.name}
                title={value.title}
                icons = {value.icons} 
              />
            ))}

            {/* Team Detail Component End */}
          </Row>

          <Row className="justify-content-center">
            {/* Team Detail Component Start */}

            {people && people.filter(obj => obj.title !== 'Partner').map((value, idx) => (
              <TeamCard
                key={idx}
                imgsrc={value.thumbnail_url}
                name={value.name}
                title={value.title}
                icons = {value.icons} 
              />
            ))}

            {/* Team Detail Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Team;



// const TeamData = [
//     {
//       imgsrc: img2,
//       name: "Juliana Waddell",
//       title: "CEO & Founder",
//       icons: [
//         {icon : "mdi mdi-facebook"},
//         {icon : "mdi mdi-instagram"},
//         {icon : "mdi mdi-whatsapp"},
//       ]
//     },
//     {
//       imgsrc: img1,
//       name: "Tyler Raymer",
//       title: "Project Manager",
//       icons: [
//         {icon : "mdi mdi-facebook"},
//         {icon : "mdi mdi-instagram"},
//         {icon : "mdi mdi-whatsapp"},
//       ]
//     },
//     {
//       imgsrc: img3,
//       name: "Chris Lewis",
//       title: "UI/UX Designer",
//       icons: [
//         {icon : "mdi mdi-facebook"},
//         {icon : "mdi mdi-instagram"},
//         {icon : "mdi mdi-whatsapp"},
//       ]
//     },
//     {
//       imgsrc: img4,
//       name: "Juliana Waddell",
//       title: "Web Developer",
//       icons: [
//         {icon : "mdi mdi-facebook"},
//         {icon : "mdi mdi-instagram"},
//         {icon : "mdi mdi-whatsapp"},
//       ]
//     },
//   ];