import React from "react";
// import routes from "./allRoutes";
import { Route, Routes } from "react-router-dom";
import Layout from "../Layout/Index";

import Layout1 from "../pages/Layout1/Layout1"
import BlogPost from "../pages/BlogPost/BlogPost";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../pages/Terms/Terms";

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path='/'  element={<Layout> <Layout1 /></Layout>}></Route>
        <Route path='/privacy-policy'  element={<PrivacyPolicy />}></Route>
        <Route path='/terms'  element={<Terms />}></Route>
        <Route path='blog-post'>
            <Route path=':blogId' element={<BlogPost />}></Route>
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;



// orignial template code, this maps all routes from allRoutes.js but that's mostly to show each of the 6 template options with the same content - changing to fixed routes for now since we don't want to show the same sections on every page
// const Index = () => {
//   return (
//     <React.Fragment>
//       <Routes>
//         <Route>
//           {routes.map((route, idx) => (
//             <Route path={route.path} element={
//               <Layout>
//                 {route.component}
//               </Layout>
//             } key={idx} />
//           ))}
//         </Route>
//         {/* <Route path='/blog' element={<Layout></Layout>}></Route> */}
//       </Routes>
//     </React.Fragment>
//   );
// };