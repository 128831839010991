import React from "react";

import About from "../components/About";
import BackToTop from "../components/BackToTop";
import Blog from "../components/Blog";
import Contact from "../components/Contact";
import Footer from "../Layout/Footer";
import FooterAlt from "../Layout/FooterAlt";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import Team from "../components/Team";

const Layout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      <Services />
      <About />
      <Blog />
      <Portfolio />
      <Team />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout;
