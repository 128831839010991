import React from "react";
import NavBar from "../../Layout/Navbar";
// import Section from "./Section";
import HeaderSection from "../../components/HeaderSection";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <NavBar isDark={false} />
      {/* import Section */}
      {/* <Section /> */}
      <HeaderSection 
            title='Terms & Conditions'
            subtitle={`Citra Solutions - ${new Date().getFullYear()}`}
        />   
        <section className="blog-post">
            <h6>Terms of use</h6>
            
            <p>Information on this website is bound by copyright laws and is for information purposes only. This information may not be used for any other purpose, saved or distributed without written prior consent.</p>
            
            <p>By using this website you are consenting to these terms of service. Your use of this website is subject to all applicable laws and regulations.</p>
            
            <p>All data displayed on this website is subject to change without notice.</p>
            
            <p>E-mail: info@CitraSolutions.com</p>
        </section>   
    </React.Fragment>
  );
};

export default PrivacyPolicy;
