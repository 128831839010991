import React, { useState } from "react";
import axios from 'axios';
import { Col, Container, Row, Form, Label, Input, FormGroup, Spinner} from "reactstrap";
import { contactApiURL } from "../globalVariables";
// import Spinner from "../UI/Spinner";

const Card = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className="contact-info text-center mt-5">
          <div className="icon">
            <i className={props.iconClass}></i>
          </div>
          <div className="mt-4 pt-2">
            <h6 className="fs-17">{props.title}</h6>
            {props.isChildItem.map((item, key) => (
              <p className="text-muted mb-0" key={key}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

// Contact Data

const ContactData = [
  {
    title: "Main Office",
    iconClass: "mdi mdi-map-marker text-primary h4",
    isChildItem: ["6 Middlemiss St", "Milsons Point NSW 2061"],
  },
  {
    title: "Phone & Email",
    iconClass: "mdi mdi-phone text-primary h4",
    isChildItem: ["Phone: +61 (0) 421 616 536"],
  },
  {
    title: "Contact",
    iconClass: "mdi mdi-email text-primary h4",
    isChildItem: ["sales@citras.io", "employment@citras.io"],
  },
  {
    title: "Working Hours",
    iconClass: "mdi mdi-calendar-clock text-primary h4",
    isChildItem: ["Monday-Friday: 08:00-18:00"],
  },
];

function scrollToContact() {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  }

const Contact = () => {

    // state
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [comments, setComments] = useState('');
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState();

    // console logs

    const submitForm = async (e) => {

        setLoading(true);
        
        scrollToContact();
        e.preventDefault();
        
        // post to contact api /form/contact
        const postData = {
            enquiry: 'general',
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phoneNumber,
            message: comments
        }

        // quick and dirty validation
        if (!postData?.firstName || !postData?.lastName || !postData?.email) {
            alert('Please fill in required form fields before submitting');
            setLoading(false);
            return false;
        }
        
        // console.log('submitting contact form data: ', postData);

        // const wait = (seconds) => new Promise(resolve => setTimeout(resolve, seconds * 1000));
        // await wait(3);        
        
        try {
            
            const response = await axios.post(`${contactApiURL}/form/contact`, postData);
            console.log('response: ', response);
            setFormSubmitted(true);
        } catch (error) {
            console.error('Error posting form:', error);
            alert('Oops, something seems to have gone wrong - Please try again later');
        } finally {
            setLoading(false);
        }

    }

    // render form or loading
    let formContent;
    if (loading || formSubmitted) {

        formContent = (
            <div className="d-flex justify-content-center">
                {loading ? <Spinner /> :  <p className="title-desc text-muted mt-3">Thank you, your request has been submitted.</p>}
            </div>
        );
       
    } else {
        formContent = (
            <Form onSubmit={submitForm} name="myForm"> {/* */}
            <p id="error-msg"></p>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <div className="mb-3">
                    <Label for="firstname" className="form-label">
                      First Name* :
                    </Label>
                    <Input
                      name="firstname"
                      id="firstname"
                      type="text"
                      className="form-control"
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}                            
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <div className="mb-3">
                    <Label for="lastname" className="form-label">
                      Last Name* :
                    </Label>
                    <Input
                      name="lastname"
                      id="lastname"
                      type="text"
                      className="form-control"
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}                            
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormGroup>
                  <div className="mb-3">
                    <Label for="email" className="form-label">
                      Email Address* :
                    </Label>
                    <Input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col lg={12}>
                <FormGroup>
                  <div className="mb-3">
                    <Label for="number" className="form-label">
                      Phone Number :
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      name="number"
                      id="number"
                      placeholder="Enter your number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}                            
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col lg={12}>
                <FormGroup>
                  <div className="mb-3">
                    <Label for="comments" className="form-label">
                      Comments :
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      placeholder="Leave a comment here"
                      name="comments"
                      id="comments"
                      style={{ height: "100px" }}
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}                            
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormGroup>
                  <div className="mt-3 text-end">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="submitBnt btn btn-primary"
                      value="Send Message"
                      
                    />
                    <div id="simple-msg"></div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>            
        );
    }

  return (

    <React.Fragment>
      <section className="section bg-light" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center mb-4">
                <p className="text-uppercase text-muted mb-2">Contact</p>
                <h3 className="text-uppercase">Get In Touch</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                Reach out to us for cutting-edge software solutions and data-driven insights to transform your business.
                </p>
              </div>
            </Col>
          </Row>

          {/*  */}

          <Row className="justify-content-center">
            {/* Contact Component Start */}

            {ContactData.map((value, idx) => (
              <Card
                key={idx}
                title={value.title}
                iconClass={value.iconClass}
                isChildItem={value.isChildItem}
              />
            ))}
            {/* Contact Component End */}
          </Row>

          <Row className="mt-5 pt-2 justify-content-center">
            <Col lg={9}>
              <div className="custom-form">
                    {formContent}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
