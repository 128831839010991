import React from "react";

import about from "../assets/images/about.jpg";
import { Container, Row, Col } from "reactstrap";

// const SkillCard = (props) => {
//   return (
//     <React.Fragment>
//       <div className="mt-4">
//         <p className="fw-bold mb-2">{props.title}</p>
//         <div className="progress">
//           <div
//             className="progress-bar rounded"
//             style={{ width: props.percentage }}
//           >
//             <p className="progress-value fs-15 fw-bold">{props.percentage}</p>
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// Skill Data

// const SkillData = [
//   {
//     title: "Web Design",
//     percentage: "80%",
//   },
//   {
//     title: "Development",
//     percentage: "60%",
//   },
//   {
//     title: "Branding",
//     percentage: "40%",
//   },
//   {
//     title: "Code",
//     percentage: "75%",
//   },
// ];

const About = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="about">
        <Container>
          <Row className="align-items-center g-3 g-lg-0">
            <Col lg={6}>
              <div className="skill-box bg-white p-4 rounded box-shadow">
                <p className="text-uppercase mb-1">About Citra</p>
                <h3 className="mb-2">
                  Leading Digital Transformations
                </h3>
                <br/>
                <p className="text-muted">
                Our founders bring over 18 years of expertise in SAP ERP, data analytics, and custom application development. 
                </p>
                <p className="text-muted">
                In 2013, we pioneered our first cloud-native solution on AWS, integrating analytics with a geo-spatial mobile web app and an SAP ERP backend.
                </p>

                <p className="text-muted">
                Since then, we have continually pushed the boundaries of innovation. We've developed a custom childcare application from the ground, up and built several modern cloud-based data platforms for major enterprise clients. 
                </p>
                <p className="text-muted">
                Our ongoing projects span various industries, consistently delivering cutting-edge solutions and driving digital transformation for our customers.
                </p>

                {/* Skill Component Start */}
                {/* <div className="skills">
                  {SkillData.map((value, idx) => (
                    <SkillCard
                      key={idx}
                      title={value.title}
                      percentage={value.percentage}
                    />
                  ))}
                </div> */}
                {/* Skill Component End */}
              </div>
            </Col>
            <Col className="about-img" lg={6}>
              <img
                src={about}
                className="img-fluid rounded box-shadow"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default About;
