import React,  {useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import {blogApiURL} from '../globalVariables';

// import img1 from "../assets/images/blog/img-1.jpg";
// import img2 from "../assets/images/blog/img-2.jpg";
// import img3 from "../assets/images/blog/img-3.jpg";
// import img4 from "../assets/images/blog/img-4.jpg";
// import img5 from "../assets/images/blog/img-5.jpg";


// import img6 from "../assets/images/users/img-6.jpg";
// import img7 from "../assets/images/users/img-7.jpg";

// BlogCard

//  const thumbnails = {
//     'img1': img1,
//     'img2': img2,
//     'img3': img3,
//     'img4': img4,
//     'img5': img5,
//  }

const BlogCard = (props) => {

    const blogLink = `/blog-post/${props.id}`

  return (
    <React.Fragment>
      <Col lg={4}>
        <div className="blog-box box-shadow rounded mt-4 p-3">
          <div className="blog-img">
            <img src={props.imgsrc} className="img-fluid rounded" alt="" />
            <div className="read-more">
              <Link to={blogLink}>
                <i className="mdi mdi-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div className="mt-4">
            <Link to={blogLink} className="primary-link">
              <h5 className="fs-19 mb-1">{props.title}</h5>
            </Link>
            <p className="text-muted mt-2">{props.subtitle}</p>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={props.imgsrc2}
                  alt=""
                  height="45"
                  className="rounded-circle me-2"
                />
                <p className="text-muted mb-0">{props.author}</p>
              </div>
              <p className="mb-0 float-end text-muted">
                <i className="mdi mdi-clock-time-four-outline align-middle me-1 text-primary"></i>{" "}
                {props.time}
              </p>
            </div>
          </div>
        </div>
        {/* <!--end blog--> */}
      </Col>
    </React.Fragment>
  );
}

// Blog Data

// const blogs = [
//   {
//     imgsrc1: img1,
//     title: "How to be appreciated for your hard work as a developer",
//     subtitle:
//       "The final text is not yet available. Dummy texts have Internet tend been in use by typesetters since the 16th century.",
//     imgsrc2: img5,
//     name: "admin",
//     time: "24 min",
//   },
//   {
//     imgsrc1: img2,
//     title: "Developers watch out for these burnout symptoms",
//     subtitle:
//       "Allegedly, a Latin scholar established the origin of the established text Internet by compiling unusual word.",
//     imgsrc2: img6,
//     name: "admin",
//     time: "32 min",
//   },
//   {
//     imgsrc1: img3,
//     title: "How designers and developers can collaborate better",
//     subtitle:
//       "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
//     imgsrc2: img7,
//     name: "admin",
//     time: "45 min",
//   },
// ];

// citra blog data
// [
//     {
//         "id": "Benefits-of-Financial-Reporting-and-Analysis-Solutions",
//         "title": "Benefits of Financial Reporting and Analysis (FP&A) solutions",
//         "datePublished": "2024-07-26",
//         "author": "Rebecca Marsh"
//     },
//     {
//         "id": "what-is-a-modern-data-platform",
//         "title": "What is a Modern Data Platform",
//         "datePublished": "2021-07-14",
//         "author": "David Simpson"
//     }
// ]

  

const Blog = () => {

    const [blogs, setBlogs] = useState([]);

    // console.log('blogs: ', blogs);

    useEffect(() => {
      const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${blogApiURL}/list`, {
                params: {'type': 'blogs'}
            }
          );
          setBlogs(response.data);
        } catch (error) {
          console.error('Error fetching blogs:', error);
        }
      };
  
      fetchBlogs();
    }, []);
    
    
    return (
        <React.Fragment>
        <section className="section" id="blog">
            <Container>
            <Row className="justify-content-center">
                <Col lg={7}>
                <div className="header-title text-center mb-5">
                    <p className="text-uppercase text-muted mb-2">Blogs</p>
                    <h3>Latest Articles</h3>
                    <div className="title-border mt-3"></div>
                    <p className="title-desc text-muted mt-3">
                    Explore our blog for the latest insights, trends, and best practices in data platforms, analytics, cloud, AI, and more. Our experts share their knowledge to help you stay ahead in the ever-evolving tech landscape.
                    </p>
                </div>
                </Col>
            </Row>

            <Row>
                {/* Blog Component Start*/}

                {blogs?.filter(obj => obj.published)?.sort((a,b) => new Date(b.datePublished).getTime() - new Date(a.datePublished).getTime()).map((value, idx) => (
                <BlogCard
                    key={idx}
                    imgsrc={value.thumbnail_url}
                    title={value.title}
                    subtitle={value.subtitle}
                    imgsrc2={value.imgsrc2}
                    author={value.author}
                    time={value.readTime}
                    id={value.id}
                />
                ))}

                {/* Blog Component End*/}
            </Row>
            </Container>
        </section>
        </React.Fragment>
    );
}

export default Blog;
