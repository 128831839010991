import React from "react";
import RouteIndex from "./Routes/RouteIndex";

//import Custom Style scss
import "./assets/scss/theme.scss";

function App() {
  return (
    <React.Fragment>
      <RouteIndex />
    </React.Fragment>
  );
}

export default App;
