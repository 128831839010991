import React from "react";
import { Col, Container, Row } from "reactstrap";

import img01 from "../assets/images/feature/img-01.png";
import img02 from "../assets/images/feature/img-02.png";
import img03 from "../assets/images/feature/img-03.png";
import img04 from "../assets/images/feature/img-04.png";
import img05 from "../assets/images/feature/img-05.png";
import img06 from "../assets/images/feature/img-06.png";

const ServicesCard = (props) => {
  return (
    <>
      <Col lg={4} md={6}>
        <div
          className={
            props.isShadow
              ? "service-box text-center mt-4 box-shadow"
              : "service-box text-center mt-4"
          }
        >
          <img src={props.imgsrc} alt="" className="img-fluid" />
          <h5 className="fs-18 mt-4">{props.title}</h5>
          <div className="lighlight-border mt-3"></div>
          <p className="text-muted mt-3">{props.caption}</p>
        </div>
      </Col>
      {/* <!--end col--> */}
    </>
  );
}

// Services data

const ServicesData = [
  {
    imgsrc: img01,
    title: "Modern Data Platforms",
    caption:
      "We design and build modern data platforms with a choice of major cloud platforms to transform your data infrastructure for enhanced efficiency and scalability.",
  },
  {
    imgsrc: img02,
    title: "Analytics",
    caption:
      "We work with all major analytics tools to unlock actionable insights from your data and drive informed decision-making.",
    isShadow: true,
  },
  {
    imgsrc: img03,
    title: "IoT and Big Data",
    caption:
      "We work with sensor and edge device providers to stream data back into your modern data platform with efficient big data storage.",
  },
  {
    imgsrc: img04,
    title: "Machine Learning & Artificial Intelligence",
    caption:
      "We design and build ML & AI algorithms that enhance your analytics and feed back into your core business processes.",
    isShadow: true,
  },
  {
    imgsrc: img05,
    title: "Cloud Solutions",
    caption:
      "We optimize your business operations with flexible and scalable cloud technologies.",
  },
  {
    imgsrc: img06,
    title: "Strategy & Architecture",
    caption:
      "We provide strategy and architecture advice across modern data platforms to support your business initiatives.",
    isShadow: true,
  },
];

const Services = () => {
  return (
    <>
      <section className="section" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}> 
              <div className="header-title text-center">
                {/* <p className="text-uppercase text-muted mb-2">
                  Services We Provide
                </p> */}
                <h3> Services We Provide</h3>
                <div className="title-border mt-3"></div>
                <p className="text-muted mt-3">
                At Citra, we empower businesses by unlocking the full potential of their data. Our expert services provide tailored insights and innovative strategies to drive success and transformation.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Services Component Start */}

            {ServicesData.map((value, idx) => (
              <ServicesCard
                key={idx}
                imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
              />
            ))}

            {/* Services Component End */}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Services;
